import * as React from 'react';
// @mui
import { Typography } from '@mui/material';
// components
import Page from 'components/Page';
// sections
import { LoginForm } from 'sections/auth/login';
import { SetNewPasswordForm } from 'sections/auth/new-password';
// layouts
import AuthLogoLayout from 'layouts/auth/AuthLogoLayout';

// ----------------------------------------------------------------------

export type LoginResponse = {
  status: string;
  user: any;
  setNewPassword: (newPassword: string) => Promise<any>;
};

export default function Login() {
  const [loginResponse, setLoginResponse] = React.useState<LoginResponse>();

  const getForm = React.useCallback(() => {
    if (loginResponse !== undefined) {
      if (loginResponse.status === 'NEW_PASSWORD') {
        return (
          <>
            <Typography variant="h3" paragraph>
              Update Temporary Password
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>
              Before logging in, you&apos;ll need to set a new password to replace the temporary one set for you when
              your account was created.
            </Typography>
            <SetNewPasswordForm temporaryPassword={{ loginResponse }} />
          </>
        );
      }
    } else {
      return <LoginForm setLoginResponse={setLoginResponse} />;
    }
  }, [loginResponse]);

  return (
    <Page title="Login">
      <AuthLogoLayout logoBottomPadding={10}>{getForm()}</AuthLogoLayout>
    </Page>
  );
}
