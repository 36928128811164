import { decode } from 'base-64';
import { API, Auth, graphqlOperation } from 'aws-amplify';

import { formatUnknownError } from './logging';
import {
  resendConfirmationEmail as resendConfirmationEmailMutation,
  setUserPassword as setUserPasswordMutation,
} from 'graphql/mutations';

/**
 * Initiates a forgot password request with the provided email address.
 * @param {string} emailAddress The email address to send the password reset request to.
 * @returns {Promise<void>} If the promise resolves, the forgot password request is initiated successfully.
 */
export const forgotPassword = async (emailAddress: string): Promise<void> => {
  try {
    await Auth.forgotPassword(emailAddress);
  } catch (error) {
    throw formatUnknownError(error);
  }
};

/**
 * Confirms a new password generated from a forgot password request. The email address
 * and confirmation code are passed from the password reset URL.
 * @param {string} encodedEmail The user's email address, encoded as a Base64 string.
 * @param {string} confirmationCode The AWS-generated confirmation code to confirm the password reset.
 * @param {string} newPassword The user's new password.
 * @returns {Promise<void>} If the promise resolves, the user's password is changed successfully.
 */
export const forgotPasswordSubmit = async (
  encodedEmail: string,
  confirmationCode: string,
  newPassword: string
): Promise<void> => {
  try {
    await Auth.forgotPasswordSubmit(decode(decodeURIComponent(encodedEmail)), confirmationCode, newPassword);
  } catch (error) {
    throw formatUnknownError(error);
  }
};

/**
 * Resends the confirmation email AWS provides upon a user signing up for an account.
 * @param {string} userId The ID of the user to send the confirmation message to.
 * @returns {Promise<string>} Resolves with the ID of the user to whom the confirmation email was sent.
 */
export const resendConfirmationEmail = async (userId: string): Promise<string> => {
  interface ResendConfirmationEmailResponse {
    data: {
      resendConfirmationEmail: string;
    };
  }
  try {
    const res = (await API.graphql(
      graphqlOperation(resendConfirmationEmailMutation, { userId })
    )) as ResendConfirmationEmailResponse;
    return res.data.resendConfirmationEmail;
  } catch (error) {
    throw new Error(error.errors[0].message);
  }
};

/**
 * Sets the specified user's password to the provided new value.
 * @param {string} userId The ID of the user whose password is being changed.
 * @param {string} password The new password.
 * @param {boolean} isTemporary Whether the password change is temporary (user must change after first sign-in) or permanent
 * @returns {Promise<string>} Resolves with the ID of the user whose password was changed.
 */
export const setUserPassword = async (userId: string, password: string, isTemporary: boolean): Promise<string> => {
  interface SetUserPasswordResponse {
    data: {
      setUserPassword: string;
    };
  }
  try {
    const res = (await API.graphql(
      graphqlOperation(setUserPasswordMutation, {
        userId,
        password,
        isTemporary,
      })
    )) as SetUserPasswordResponse;
    return res.data.setUserPassword;
  } catch (error) {
    throw new Error(error.errors[0].message);
  }
};
