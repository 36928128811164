// scroll bar
import 'simplebar/src/simplebar.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// contexts
import { SettingsProvider } from 'contexts/SettingsContext';
import { CollapseDrawerProvider } from 'contexts/CollapseDrawerContext';
import { ZoomProvider } from 'contexts/ZoomContext';
import { AuthProvider } from 'contexts/AwsCognitoContext';

//
import App from 'App';
import { Amplify } from 'aws-amplify';
import awsExports from 'aws-exports';

// ----------------------------------------------------------------------

Amplify.configure({
  ...awsExports,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_WEB_DASHBOARD_CLIENT_ID,
});

ReactDOM.render(
  <AuthProvider>
    <HelmetProvider>
      <ZoomProvider>
        <SettingsProvider>
          <CollapseDrawerProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </CollapseDrawerProvider>
        </SettingsProvider>
      </ZoomProvider>
    </HelmetProvider>
  </AuthProvider>,
  document.getElementById('root')
);
