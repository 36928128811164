// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_QUEUE = `${ROOTS_DASHBOARD}/queue`;
const ROOTS_USER = `${ROOTS_DASHBOARD}/users`;

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  setNewPassword: path(ROOTS_AUTH, '/new-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  redirect: path(ROOTS_AUTH, '/redirect'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  queue: {
    root: ROOTS_QUEUE,
    videoCall: path(ROOTS_QUEUE, '/video-call'),
  },
  settings: path(ROOTS_DASHBOARD, '/system-settings'),
  users: {
    root: ROOTS_USER,
    profile: path(ROOTS_USER, '/profile'),
    new: path(ROOTS_USER, '/new'),
    edit: path(ROOTS_USER, '/edit'),
    approve: path(ROOTS_USER, '/approve'),
    delete: path(ROOTS_USER, '/delete'),
  },
};
