import * as React from 'react';
import { useRequest } from 'ahooks';
import { Howl } from 'howler';
import { useSnackbar } from 'notistack';

import { UserInQueue } from 'API';
import {
  getSupportQueue,
  removeUserFromQueue,
  subscribeToJoinSupportQueue,
  subscribeToLeaveSupportQueue,
} from 'utils/queuePosition';

import joinQueue from '../assets/join_queue.wav';

type QueueContextType = {
  currentTime: Date;
  removeUser: (username: string) => void;
  usersInQueue: UserInQueue[] | undefined;
};

const QueueContext = React.createContext<QueueContextType | null>(null);

type QueueProviderProps = {
  children: React.ReactNode;
};

const notificationSound = new Howl({
  src: [joinQueue],
});

function QueueProvider({ children }: QueueProviderProps) {
  const { enqueueSnackbar } = useSnackbar();

  const { data: usersInQueue, run: getUsersInQueue } = useRequest(getSupportQueue, {
    onError: () => {
      enqueueSnackbar('Information is out of date. Please refresh the page!', { persist: true, variant: 'error' });
    },
  });

  React.useEffect(() => {
    const onClientLeftQueue = () => {
      getUsersInQueue();
    };

    const onClientJoinedQueue = () => {
      getUsersInQueue();
      notificationSound.play();
      enqueueSnackbar('A client just joined the queue', { variant: 'info' });
    };

    const leaveSupportQueueSub = subscribeToLeaveSupportQueue(onClientLeftQueue);
    const joinSupportQueueSub = subscribeToJoinSupportQueue(onClientJoinedQueue);
    return () => {
      leaveSupportQueueSub.unsubscribe();
      joinSupportQueueSub.unsubscribe();
      notificationSound.unload();
    };
  }, [enqueueSnackbar, getUsersInQueue]);

  const [currentTime, setCurrentTime] = React.useState(new Date());
  React.useEffect(() => {
    /** Update the current time every minute to refresh the display of time in queue */
    const refreshCurrentTimeEveryMinute = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => {
      clearInterval(refreshCurrentTimeEveryMinute);
    };
  }, []);

  const { run: removeUser } = useRequest(removeUserFromQueue, {
    manual: true,
    onSuccess: () => {
      enqueueSnackbar('User removed from the queue');
    },
    onError: () => {
      enqueueSnackbar('User could not be removed', { variant: 'error' });
    },
  });

  return <QueueContext.Provider value={{ currentTime, removeUser, usersInQueue }}>{children}</QueueContext.Provider>;
}

export { QueueContext, QueueProvider };
