/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-1",
    "aws_appsync_graphqlEndpoint": "https://xabd6hck4ngkbfp6l2avxxhlfa.appsync-api.us-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-west-1:524f18fa-4baa-49f2-b142-f354573d7d71",
    "aws_cognito_region": "us-west-1",
    "aws_user_pools_id": "us-west-1_CcaVsHM72",
    "aws_user_pools_web_client_id": "5seprckdlh9siqcp49jmtgmfh3",
    "oauth": {
        "domain": "boundless43eps8sj-43eps8sj-production.auth.us-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "boundless://oauth-redirect",
        "redirectSignOut": "boundless://start",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "SupportQueue-production",
            "region": "us-west-1"
        }
    ]
};


export default awsmobile;
