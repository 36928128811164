import { format, getTime, formatDistanceToNow, parse } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function toAWSDate(date: Date | string | number) {
  return format(new Date(date), 'yyyy-MM-dd');
}

export function fromAWSDate(date: string) {
  return parse(date, 'yyyy-MM-dd', new Date());
}

export const fromDBStringToDate = (time: string) => {
  const [hours, minutes] = time.split(':').map((s) => parseInt(s, 10));
  return new Date(0, 0, 0, hours, minutes);
};

export const fToHourMinutesString = (time: Date) => {
  return time.toLocaleTimeString('en-us', { hour: '2-digit', minute: '2-digit', hourCycle: 'h24' });
};
