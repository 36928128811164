import * as React from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from 'routes/paths';
// hooks
import useIsMountedRef from 'hooks/useIsMountedRef';
import useAuth from 'hooks/useAuth';
// components
import { FormProvider } from 'components/hook-form';
import { PasswordInput } from 'components/PasswordInput';
// utils
import { forgotPasswordSubmit } from 'utils/authentication';
// types
import { LoginResponse } from 'pages/auth/Login';

// ----------------------------------------------------------------------

type Props = {
  temporaryPassword?: {
    loginResponse: LoginResponse;
  };
  forgotPassword?: {
    email: string;
    confirmationCode: string;
  };
};

type FormValuesProps = {
  password: string;
  confirmPassword: string;
  afterSubmit?: string;
};

export default function SetNewPasswordForm({ temporaryPassword, forgotPassword }: Props) {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const { getCurrentSession } = useAuth();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const newPasswordSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Password does not match')
      .required('Please confirm your password'),
  });

  const defaultValues = {
    password: '',
    confirmPassword: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(newPasswordSchema),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      if (forgotPassword !== undefined) {
        await forgotPasswordSubmit(forgotPassword.email, forgotPassword.confirmationCode, data.password);
        enqueueSnackbar('Password reset successfully');
        navigate(PATH_AUTH.login, { replace: true });
      } else if (temporaryPassword !== undefined) {
        await temporaryPassword.loginResponse.setNewPassword(data.password);
        enqueueSnackbar('Password updated successfully');
        getCurrentSession();
      }
    } catch (error) {
      if (isMountedRef.current) {
        setError('afterSubmit', { message: error.message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {Boolean(errors.afterSubmit) && <Alert severity="error">{errors.afterSubmit?.message}</Alert>}

        <PasswordInput name="password" label="Password" showPassword={showPassword} setShowPassword={setShowPassword} />

        <PasswordInput
          name="confirmPassword"
          label="Confirm password"
          showPassword={showConfirmPassword}
          setShowPassword={setShowConfirmPassword}
        />
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} sx={{ mt: 3 }}>
        Set password
      </LoadingButton>
    </FormProvider>
  );
}
