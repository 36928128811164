/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ModelSupportAvailabilityWeeklyFilterInput = {
  id?: ModelIDInput | null;
  isEnabled?: ModelBooleanInput | null;
  and?: Array<ModelSupportAvailabilityWeeklyFilterInput | null> | null;
  or?: Array<ModelSupportAvailabilityWeeklyFilterInput | null> | null;
  not?: ModelSupportAvailabilityWeeklyFilterInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = 'binary',
  binarySet = 'binarySet',
  bool = 'bool',
  list = 'list',
  map = 'map',
  number = 'number',
  numberSet = 'numberSet',
  string = 'string',
  stringSet = 'stringSet',
  _null = '_null',
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelSupportAvailabilityWeeklyConnection = {
  __typename: 'ModelSupportAvailabilityWeeklyConnection';
  items: Array<SupportAvailabilityWeekly | null>;
  nextToken?: string | null;
};

export type SupportAvailabilityWeekly = {
  __typename: 'SupportAvailabilityWeekly';
  id: string;
  day0: SupportAvailabilityDaily;
  day1: SupportAvailabilityDaily;
  day2: SupportAvailabilityDaily;
  day3: SupportAvailabilityDaily;
  day4: SupportAvailabilityDaily;
  day5: SupportAvailabilityDaily;
  day6: SupportAvailabilityDaily;
  isEnabled: boolean;
  createdAt: string;
  updatedAt: string;
};

export type SupportAvailabilityDaily = {
  __typename: 'SupportAvailabilityDaily';
  closeAt: string;
  isEnabled: boolean;
  openAt: string;
};

export type CreateUserInfo = {
  address?: Address | null;
  email: string;
  expirationDate?: string | null;
  familyName: string;
  givenName: string;
  group: UserGroups;
  netsuiteUrl?: string | null;
  phoneNumber: string;
};

export type Address = {
  streetAddress: string;
  locality: string;
  region: string;
  postalCode: string;
  country: Country;
};

export enum Country {
  USA = 'USA',
}

export enum UserGroups {
  ADMIN = 'ADMIN',
  AGENT = 'AGENT',
  PUBLIC = 'PUBLIC',
}

export enum CallType {
  APPOINTMENT = 'APPOINTMENT',
  QUICK = 'QUICK',
}

export type PublicUserInQueue = {
  __typename: 'PublicUserInQueue';
  positionId: string;
  status: UserInQueueStatus;
};

export type UserInQueuePublicInfo = {
  __typename: 'UserInQueuePublicInfo';
  positionId: string;
  status: UserInQueueStatus;
};

export type UserInQueue = {
  __typename: 'UserInQueue';
  username: string;
  agentUsername?: string | null;
  positionId: string;
  zoomSessionId?: string | null;
  givenName: string;
  familyName: string;
  callType: CallTypeOutput;
  phoneNumber: string;
  tags: Array<UserNoteType>;
  status: UserInQueueStatus;
  joinedAt: string;
};

export enum CallTypeOutput {
  APPOINTMENT = 'APPOINTMENT',
  QUICK = 'QUICK',
  NONE = 'NONE',
}

export enum UserNoteType {
  ALERT = 'ALERT',
  INFO = 'INFO',
  WARNING = 'WARNING',
}

export enum UserInQueueStatus {
  IN_QUEUE = 'IN_QUEUE',
  IN_CALL = 'IN_CALL',
}

export enum QueueRemovalReason {
  CALL_STARTED = 'CALL_STARTED',
}

export type UpdateUserInfo = {
  address?: Address | null;
  email?: string | null;
  expirationDate?: string | null;
  familyName?: string | null;
  givenName?: string | null;
  group?: UserGroups | null;
  netsuiteUrl?: string | null;
  phoneNumber?: string | null;
  tags?: Array<UserNoteType> | null;
};

export type CreateUserNoteInput = {
  clientId: string;
  agentEmail: string;
  agentId: string;
  createdAt?: string | null;
  content: string;
  name: string;
  type: UserNoteType;
};

export type ModelUserNoteConditionInput = {
  agentEmail?: ModelStringInput | null;
  agentId?: ModelIDInput | null;
  content?: ModelStringInput | null;
  name?: ModelStringInput | null;
  type?: ModelUserNoteTypeInput | null;
  and?: Array<ModelUserNoteConditionInput | null> | null;
  or?: Array<ModelUserNoteConditionInput | null> | null;
  not?: ModelUserNoteConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelUserNoteTypeInput = {
  eq?: UserNoteType | null;
  ne?: UserNoteType | null;
};

export type UserNote = {
  __typename: 'UserNote';
  clientId: string;
  agentEmail: string;
  agentId: string;
  createdAt: string;
  content: string;
  name: string;
  type: UserNoteType;
  updatedAt: string;
};

export type UpdateUserNoteInput = {
  clientId: string;
  agentEmail?: string | null;
  agentId?: string | null;
  createdAt: string;
  content?: string | null;
  name?: string | null;
  type?: UserNoteType | null;
};

export type DeleteUserNoteInput = {
  clientId: string;
  createdAt: string;
};

export type UpdateSupportAvailabilityWeeklyInput = {
  id: string;
  day0?: SupportAvailabilityDailyInput | null;
  day1?: SupportAvailabilityDailyInput | null;
  day2?: SupportAvailabilityDailyInput | null;
  day3?: SupportAvailabilityDailyInput | null;
  day4?: SupportAvailabilityDailyInput | null;
  day5?: SupportAvailabilityDailyInput | null;
  day6?: SupportAvailabilityDailyInput | null;
  isEnabled?: boolean | null;
};

export type SupportAvailabilityDailyInput = {
  closeAt: string;
  isEnabled: boolean;
  openAt: string;
};

export type ModelSupportAvailabilityWeeklyConditionInput = {
  isEnabled?: ModelBooleanInput | null;
  and?: Array<ModelSupportAvailabilityWeeklyConditionInput | null> | null;
  or?: Array<ModelSupportAvailabilityWeeklyConditionInput | null> | null;
  not?: ModelSupportAvailabilityWeeklyConditionInput | null;
};

export type ZoomSession = {
  __typename: 'ZoomSession';
  sessionId: string;
  sessionToken: string;
};

export type User = {
  __typename: 'User';
  id: string;
  expirationDate?: string | null;
  address?: UserAddress | null;
  isApproved: boolean;
  isConfirmed: boolean;
  isOAuth: boolean;
  identities?: Array<OAuthIdentity> | null;
  reasonForDisable?: string | null;
  email: string;
  givenName: string;
  familyName: string;
  netsuiteUrl?: string | null;
  phoneNumber?: string | null;
  group: UserGroups;
  tags?: Array<UserNoteType> | null;
};

export type UserAddress = {
  __typename: 'UserAddress';
  street?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
};

export type OAuthIdentity = {
  __typename: 'OAuthIdentity';
  dateCreated?: string | null;
  issuer?: string | null;
  primary?: boolean | null;
  providerName?: string | null;
  providerType?: string | null;
  userId?: string | null;
};

export type ListUsersResponse = {
  __typename: 'ListUsersResponse';
  users: Array<User>;
  nextToken?: string | null;
};

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelUserNoteFilterInput = {
  clientId?: ModelIDInput | null;
  agentEmail?: ModelStringInput | null;
  agentId?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  content?: ModelStringInput | null;
  name?: ModelStringInput | null;
  type?: ModelUserNoteTypeInput | null;
  and?: Array<ModelUserNoteFilterInput | null> | null;
  or?: Array<ModelUserNoteFilterInput | null> | null;
  not?: ModelUserNoteFilterInput | null;
};

export enum ModelSortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type ModelUserNoteConnection = {
  __typename: 'ModelUserNoteConnection';
  items: Array<UserNote | null>;
  nextToken?: string | null;
};

export type ListSupportAvailabilityQueryVariables = {
  filter?: ModelSupportAvailabilityWeeklyFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSupportAvailabilityQuery = {
  listSupportAvailabilityWeeklies?: {
    __typename: 'ModelSupportAvailabilityWeeklyConnection';
    items: Array<{
      __typename: 'SupportAvailabilityWeekly';
      id: string;
      isEnabled: boolean;
      day0: {
        __typename: 'SupportAvailabilityDaily';
        closeAt: string;
        isEnabled: boolean;
        openAt: string;
      };
      day1: {
        __typename: 'SupportAvailabilityDaily';
        closeAt: string;
        isEnabled: boolean;
        openAt: string;
      };
      day2: {
        __typename: 'SupportAvailabilityDaily';
        closeAt: string;
        isEnabled: boolean;
        openAt: string;
      };
      day3: {
        __typename: 'SupportAvailabilityDaily';
        closeAt: string;
        isEnabled: boolean;
        openAt: string;
      };
      day4: {
        __typename: 'SupportAvailabilityDaily';
        closeAt: string;
        isEnabled: boolean;
        openAt: string;
      };
      day5: {
        __typename: 'SupportAvailabilityDaily';
        closeAt: string;
        isEnabled: boolean;
        openAt: string;
      };
      day6: {
        __typename: 'SupportAvailabilityDaily';
        closeAt: string;
        isEnabled: boolean;
        openAt: string;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ChangeUserStatusMutationVariables = {
  userId: string;
  isUserApproved: boolean;
};

export type ChangeUserStatusMutation = {
  changeUserStatus?: boolean | null;
};

export type CreateUserMutationVariables = {
  userInfo: CreateUserInfo;
};

export type CreateUserMutation = {
  createUser?: string | null;
};

export type DeleteUserMutationVariables = {
  userId: string;
};

export type DeleteUserMutation = {
  deleteUser?: string | null;
};

export type JoinSupportQueueMutationVariables = {
  callType?: CallType | null;
};

export type JoinSupportQueueMutation = {
  joinSupportQueue?: string | null;
};

export type LeaveSupportQueueMutation = {
  leaveSupportQueue?: Array<{
    __typename: 'PublicUserInQueue';
    positionId: string;
    status: UserInQueueStatus;
  }> | null;
};

export type RemoveUserFromQueueMutationVariables = {
  userId: string;
  reason?: QueueRemovalReason | null;
};

export type RemoveUserFromQueueMutation = {
  removeUserFromQueue?: Array<{
    __typename: 'PublicUserInQueue';
    positionId: string;
    status: UserInQueueStatus;
  }> | null;
};

export type ResendConfirmationEmailMutationVariables = {
  userId: string;
};

export type ResendConfirmationEmailMutation = {
  resendConfirmationEmail?: string | null;
};

export type RequestAccountDeletionMutation = {
  requestAccountDeletion?: string | null;
};

export type SendCallStartedNotificationMutationVariables = {
  clientToNotify: string;
};

export type SendCallStartedNotificationMutation = {
  sendCallStartedNotification?: boolean | null;
};

export type SetUserPasswordMutationVariables = {
  userId: string;
  password: string;
  isTemporary: boolean;
};

export type SetUserPasswordMutation = {
  setUserPassword?: string | null;
};

export type UpdateUserMutationVariables = {
  userId: string;
  updatedUserInfo: UpdateUserInfo;
};

export type UpdateUserMutation = {
  updateUser?: string | null;
};

export type CreateUserNoteMutationVariables = {
  input: CreateUserNoteInput;
  condition?: ModelUserNoteConditionInput | null;
};

export type CreateUserNoteMutation = {
  createUserNote?: {
    __typename: 'UserNote';
    clientId: string;
    agentEmail: string;
    agentId: string;
    createdAt: string;
    content: string;
    name: string;
    type: UserNoteType;
    updatedAt: string;
  } | null;
};

export type UpdateUserNoteMutationVariables = {
  input: UpdateUserNoteInput;
  condition?: ModelUserNoteConditionInput | null;
};

export type UpdateUserNoteMutation = {
  updateUserNote?: {
    __typename: 'UserNote';
    clientId: string;
    agentEmail: string;
    agentId: string;
    createdAt: string;
    content: string;
    name: string;
    type: UserNoteType;
    updatedAt: string;
  } | null;
};

export type DeleteUserNoteMutationVariables = {
  input: DeleteUserNoteInput;
  condition?: ModelUserNoteConditionInput | null;
};

export type DeleteUserNoteMutation = {
  deleteUserNote?: {
    __typename: 'UserNote';
    clientId: string;
    agentEmail: string;
    agentId: string;
    createdAt: string;
    content: string;
    name: string;
    type: UserNoteType;
    updatedAt: string;
  } | null;
};

export type UpdateSupportAvailabilityWeeklyMutationVariables = {
  input: UpdateSupportAvailabilityWeeklyInput;
  condition?: ModelSupportAvailabilityWeeklyConditionInput | null;
};

export type UpdateSupportAvailabilityWeeklyMutation = {
  updateSupportAvailabilityWeekly?: {
    __typename: 'SupportAvailabilityWeekly';
    id: string;
    day0: {
      __typename: 'SupportAvailabilityDaily';
      closeAt: string;
      isEnabled: boolean;
      openAt: string;
    };
    day1: {
      __typename: 'SupportAvailabilityDaily';
      closeAt: string;
      isEnabled: boolean;
      openAt: string;
    };
    day2: {
      __typename: 'SupportAvailabilityDaily';
      closeAt: string;
      isEnabled: boolean;
      openAt: string;
    };
    day3: {
      __typename: 'SupportAvailabilityDaily';
      closeAt: string;
      isEnabled: boolean;
      openAt: string;
    };
    day4: {
      __typename: 'SupportAvailabilityDaily';
      closeAt: string;
      isEnabled: boolean;
      openAt: string;
    };
    day5: {
      __typename: 'SupportAvailabilityDaily';
      closeAt: string;
      isEnabled: boolean;
      openAt: string;
    };
    day6: {
      __typename: 'SupportAvailabilityDaily';
      closeAt: string;
      isEnabled: boolean;
      openAt: string;
    };
    isEnabled: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type GetCallSessionTokenQuery = {
  getCallSessionToken?: {
    __typename: 'ZoomSession';
    sessionId: string;
    sessionToken: string;
  } | null;
};

export type GetCallSessionTokenAsAgentQueryVariables = {
  clientUserId: string;
};

export type GetCallSessionTokenAsAgentQuery = {
  getCallSessionTokenAsAgent?: {
    __typename: 'ZoomSession';
    sessionId: string;
    sessionToken: string;
  } | null;
};

export type GetNumberOfUsersQuery = {
  getNumberOfUsers?: number | null;
};

export type GetUserQueryVariables = {
  userId: string;
};

export type GetUserQuery = {
  getUser?: {
    __typename: 'User';
    id: string;
    expirationDate?: string | null;
    address?: {
      __typename: 'UserAddress';
      street?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
    } | null;
    isApproved: boolean;
    isConfirmed: boolean;
    isOAuth: boolean;
    identities?: Array<{
      __typename: 'OAuthIdentity';
      dateCreated?: string | null;
      issuer?: string | null;
      primary?: boolean | null;
      providerName?: string | null;
      providerType?: string | null;
      userId?: string | null;
    }> | null;
    reasonForDisable?: string | null;
    email: string;
    givenName: string;
    familyName: string;
    netsuiteUrl?: string | null;
    phoneNumber?: string | null;
    group: UserGroups;
    tags?: Array<UserNoteType> | null;
  } | null;
};

export type GetSupportQueueQuery = {
  getSupportQueue?: Array<{
    __typename: 'PublicUserInQueue';
    positionId: string;
    status: UserInQueueStatus;
  }> | null;
};

export type GetSupportQueueAsAgentQuery = {
  getSupportQueueAsAgent?: Array<{
    __typename: 'UserInQueue';
    username: string;
    agentUsername?: string | null;
    positionId: string;
    zoomSessionId?: string | null;
    givenName: string;
    familyName: string;
    callType: CallTypeOutput;
    phoneNumber: string;
    tags: Array<UserNoteType>;
    status: UserInQueueStatus;
    joinedAt: string;
  }> | null;
};

export type ListUsersQueryVariables = {
  nextToken?: string | null;
  filter?: string | null;
  groupName?: string | null;
};

export type ListUsersQuery = {
  listUsers?: {
    __typename: 'ListUsersResponse';
    users: Array<{
      __typename: 'User';
      id: string;
      expirationDate?: string | null;
      address?: {
        __typename: 'UserAddress';
        street?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
      } | null;
      isApproved: boolean;
      isConfirmed: boolean;
      isOAuth: boolean;
      identities?: Array<{
        __typename: 'OAuthIdentity';
        dateCreated?: string | null;
        issuer?: string | null;
        primary?: boolean | null;
        providerName?: string | null;
        providerType?: string | null;
        userId?: string | null;
      }> | null;
      reasonForDisable?: string | null;
      email: string;
      givenName: string;
      familyName: string;
      netsuiteUrl?: string | null;
      phoneNumber?: string | null;
      group: UserGroups;
      tags?: Array<UserNoteType> | null;
    }>;
    nextToken?: string | null;
  } | null;
};

export type IsSupportOpenQuery = {
  isSupportOpen?: boolean | null;
};

export type GetUserNoteQueryVariables = {
  clientId: string;
  createdAt: string;
};

export type GetUserNoteQuery = {
  getUserNote?: {
    __typename: 'UserNote';
    clientId: string;
    agentEmail: string;
    agentId: string;
    createdAt: string;
    content: string;
    name: string;
    type: UserNoteType;
    updatedAt: string;
  } | null;
};

export type ListUserNotesQueryVariables = {
  clientId?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  filter?: ModelUserNoteFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: ModelSortDirection | null;
};

export type ListUserNotesQuery = {
  listUserNotes?: {
    __typename: 'ModelUserNoteConnection';
    items: Array<{
      __typename: 'UserNote';
      clientId: string;
      agentEmail: string;
      agentId: string;
      createdAt: string;
      content: string;
      name: string;
      type: UserNoteType;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSupportAvailabilityWeeklyQueryVariables = {
  id: string;
};

export type GetSupportAvailabilityWeeklyQuery = {
  getSupportAvailabilityWeekly?: {
    __typename: 'SupportAvailabilityWeekly';
    id: string;
    day0: {
      __typename: 'SupportAvailabilityDaily';
      closeAt: string;
      isEnabled: boolean;
      openAt: string;
    };
    day1: {
      __typename: 'SupportAvailabilityDaily';
      closeAt: string;
      isEnabled: boolean;
      openAt: string;
    };
    day2: {
      __typename: 'SupportAvailabilityDaily';
      closeAt: string;
      isEnabled: boolean;
      openAt: string;
    };
    day3: {
      __typename: 'SupportAvailabilityDaily';
      closeAt: string;
      isEnabled: boolean;
      openAt: string;
    };
    day4: {
      __typename: 'SupportAvailabilityDaily';
      closeAt: string;
      isEnabled: boolean;
      openAt: string;
    };
    day5: {
      __typename: 'SupportAvailabilityDaily';
      closeAt: string;
      isEnabled: boolean;
      openAt: string;
    };
    day6: {
      __typename: 'SupportAvailabilityDaily';
      closeAt: string;
      isEnabled: boolean;
      openAt: string;
    };
    isEnabled: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListSupportAvailabilityWeekliesQueryVariables = {
  filter?: ModelSupportAvailabilityWeeklyFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSupportAvailabilityWeekliesQuery = {
  listSupportAvailabilityWeeklies?: {
    __typename: 'ModelSupportAvailabilityWeeklyConnection';
    items: Array<{
      __typename: 'SupportAvailabilityWeekly';
      id: string;
      day0: {
        __typename: 'SupportAvailabilityDaily';
        closeAt: string;
        isEnabled: boolean;
        openAt: string;
      };
      day1: {
        __typename: 'SupportAvailabilityDaily';
        closeAt: string;
        isEnabled: boolean;
        openAt: string;
      };
      day2: {
        __typename: 'SupportAvailabilityDaily';
        closeAt: string;
        isEnabled: boolean;
        openAt: string;
      };
      day3: {
        __typename: 'SupportAvailabilityDaily';
        closeAt: string;
        isEnabled: boolean;
        openAt: string;
      };
      day4: {
        __typename: 'SupportAvailabilityDaily';
        closeAt: string;
        isEnabled: boolean;
        openAt: string;
      };
      day5: {
        __typename: 'SupportAvailabilityDaily';
        closeAt: string;
        isEnabled: boolean;
        openAt: string;
      };
      day6: {
        __typename: 'SupportAvailabilityDaily';
        closeAt: string;
        isEnabled: boolean;
        openAt: string;
      };
      isEnabled: boolean;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnLeaveSupportQueueSubscription = {
  onLeaveSupportQueue?: Array<{
    __typename: 'PublicUserInQueue';
    positionId: string;
    status: UserInQueueStatus;
  }> | null;
};

export type OnJoinSupportQueueSubscription = {
  onJoinSupportQueue?: string | null;
};
