import * as React from 'react';
import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from 'routes/paths';
// hooks
import useAuth from 'hooks/useAuth';
import useIsMountedRef from 'hooks/useIsMountedRef';
// components
import { FormProvider, RHFTextField } from 'components/hook-form';
import { PasswordInput } from 'components/PasswordInput';
// types
import { LoginResponse } from 'pages/auth/Login';

// ----------------------------------------------------------------------

type Props = {
  setLoginResponse: React.Dispatch<React.SetStateAction<LoginResponse | undefined>>;
};

type FormValuesProps = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

export default function LoginForm({ setLoginResponse }: Props) {
  const { login } = useAuth();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const res = (await login(data.email, data.password)) as LoginResponse | undefined;
      if (res !== undefined) {
        setLoginResponse(res);
      }
    } catch (error) {
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', { message: error.message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {Boolean(errors.afterSubmit) && <Alert severity="error">{errors.afterSubmit?.message}</Alert>}

        <RHFTextField name="email" label="Email address" />

        <PasswordInput name="password" label="Password" showPassword={showPassword} setShowPassword={setShowPassword} />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Login
        </LoadingButton>
      </Stack>

      <Stack direction="row" justifyContent="flex-end" sx={{ my: 2 }}>
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          Forgot password?
        </Link>
      </Stack>
    </FormProvider>
  );
}
