import * as React from 'react';
import ZoomVideo, { Stream, VideoClient } from '@zoom/videosdk';

import { getZoomSessionToken } from 'utils/zoom';
import { formatUnknownError } from 'utils/logging';

type ZoomContextType = {
  zoomClient: typeof VideoClient | null;
  mediaStream: typeof Stream | null;
  createAndJoinSession: (clientUserId: string, agentDisplayName: string) => Promise<void>;
  leaveSession: () => Promise<void>;
};

const ZoomContext = React.createContext<ZoomContextType | null>(null);

type ZoomProviderProps = {
  children: React.ReactNode;
};

function ZoomProvider({ children }: ZoomProviderProps) {
  const [zoomClient, setZoomClient] = React.useState<typeof VideoClient | null>(null);
  const [mediaStream, setMediaStream] = React.useState<typeof Stream | null>(null);

  // Create a new session token with the user possessing the provided ID, and then join that
  // session once it has been created.
  const createAndJoinSession = React.useCallback(
    async (clientUserId: string, agentDisplayName: string) => {
      try {
        if (zoomClient) {
          const { sessionId, sessionToken } = await getZoomSessionToken(clientUserId);
          await zoomClient.join(sessionId, sessionToken, agentDisplayName);
          setMediaStream(zoomClient.getMediaStream());
        }
      } catch (error) {
        throw formatUnknownError(error);
      }
    },
    [zoomClient]
  );

  // Leave a Zoom session. Since the agent initiating the call is the host, they are
  // able to end the session. Doing so removes all of the other users automatically.
  const leaveSession = React.useCallback(async () => {
    try {
      if (zoomClient && zoomClient.isHost()) {
        await zoomClient.leave(true);
      }
    } catch (error) {
      console.error(error);
      throw formatUnknownError(error);
    }
  }, [zoomClient]);

  // Initialize the Zoom client when the app loads. The client can be reused for multiple sessions,
  // so it only needs to be initialized once.
  React.useEffect(() => {
    async function init() {
      try {
        const client = ZoomVideo.createClient();
        await client.init('en-US', 'Global');
        setZoomClient(client);
      } catch (error) {
        throw formatUnknownError(error);
      }
    }
    init();
    return () => {
      ZoomVideo.destroyClient();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ZoomContext.Provider value={{ zoomClient, mediaStream, createAndJoinSession, leaveSession }}>
      {children}
    </ZoomContext.Provider>
  );
}

export { ZoomContext, ZoomProvider };
