// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// ----------------------------------------------------------------------

interface IProps {
  name: string;
}

export default function RHFTimePicker({ name, ...other }: IProps & TextFieldProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            disabled={other.disabled}
            {...field}
            renderInput={(params) => (
              <TextField {...params} fullWidth error={!!error} helperText={error?.message} {...other} />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
}
