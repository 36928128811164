import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// guards
import useAuth from 'hooks/useAuth';
// layouts
import DashboardLayout from 'layouts/dashboard';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
// guards
import GuestGuard from 'guards/GuestGuard';
import AuthGuard from 'guards/AuthGuard';
// config
import { PATH_AFTER_LOGIN } from 'config';
// components
import LoadingScreen from 'components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <SetNewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'redirect', element: <MobileRedirect /> },
      ],
    },
    // Dashboard Routes
    {
      // TODO: The extra 'dashboard' in the URL might be redundant
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'queue',
          children: [
            { index: true, element: <SupportQueue /> },
            { path: 'video-call/:id', element: <VideoCall /> },
          ],
        },
        {
          path: 'users',
          children: [
            { index: true, element: <UserManagement /> },
            { path: 'new', element: <NewUser /> },
            { path: 'edit/:id', element: <EditUser /> },
            { path: 'approve/:id', element: <ViewUser /> },
            { path: 'profile/:id', element: <EditUser /> },
            { path: 'delete/:id', element: <ViewUser /> },
          ],
        },
        { path: 'system-settings', element: <SystemSettings /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('pages/auth/Login')));
const Register = Loadable(lazy(() => import('pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('pages/auth/ResetPassword')));
const SetNewPassword = Loadable(lazy(() => import('pages/auth/SetNewPassword')));
const VerifyCode = Loadable(lazy(() => import('pages/auth/VerifyCode')));
const MobileRedirect = Loadable(lazy(() => import('pages/auth/MobileRedirect')));
// Dashboard
const SupportQueue = Loadable(lazy(() => import('pages/dashboard/SupportQueue')));
const SystemSettings = Loadable(lazy(() => import('pages/dashboard/SystemSettings')));
const UserManagement = Loadable(lazy(() => import('pages/dashboard/UserManagement')));
const VideoCall = Loadable(lazy(() => import('pages/dashboard/VideoCall')));
// User profile
const ViewUser = Loadable(lazy(() => import('pages/dashboard/profile/ViewUser')));
const EditUser = Loadable(lazy(() => import('pages/dashboard/profile/EditUser')));
const NewUser = Loadable(lazy(() => import('pages/dashboard/profile/NewUser')));
// Main
const Page500 = Loadable(lazy(() => import('pages/Page500')));
const NotFound = Loadable(lazy(() => import('pages/Page404')));
