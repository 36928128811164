// routes
import { PATH_DASHBOARD } from 'routes/paths';
// components
import SvgIconStyle from 'components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  queue: getIcon('ic_dashboard'),
  user: getIcon('ic_user'),
  settings: getIcon('ic_analytics'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Support queue', path: PATH_DASHBOARD.queue.root, icon: ICONS.queue },
      { title: 'User management', path: PATH_DASHBOARD.users.root, icon: ICONS.user },
      { title: 'System settings', path: PATH_DASHBOARD.settings, icon: ICONS.settings },
    ],
  },
];

export default navConfig;
