import { Address } from 'types/user';
import { stripNonNumericChars } from './formatText';

type AwsAddress = {
  streetAddress?: string;
  locality?: string;
  region?: string;
  postalCode?: string;
  country?: string;
};

type AuthUserAddress = {
  street_address?: string;
  locality?: string;
  region?: string;
  postal_code?: string;
  country?: string;
};

/**
 * Formats an Address object into a single string containing all of its values. If
 * all of the Address values are present, the expected format is "street, city state, zip."
 * Commas and spaces are added / removed based on any undefined values.
 * @param {Address} address The address object being formatted.
 * @returns {string} The formatted address string.
 */
export const addressToString = (address: Address): string =>
  `${address.street}${address.street && (address.city || address.state || address.zipCode) && ', '}${address.city}${
    address.state && address.city && ' '
  }${address.state}${address.zipCode && (address.city || address.state) && ', '}${address.zipCode}`;

/**
 * Converts an Address object used on the front-end into a format that AWS expects.
 * @param {Address} address The address object being formatted
 * @returns {AwsAddress} An address object formatted using AWS key names.
 */
export const convertToAwsAddressObject = (address: Address): AwsAddress => {
  return {
    streetAddress: address.street,
    locality: address.city,
    region: address.state,
    postalCode: address.zipCode,
    country: 'USA',
  };
};

/**
 * Converts an AuthUserAddress object used on the back-end into a format that front-end expects.
 * @param {AuthUserAddress} address The address object being formatted
 * @returns {Address} An address object formatted using front-end key names.
 */
export const convertFromAwsAddressObject = (address: AuthUserAddress): Address => {
  return {
    street: address.street_address,
    city: address.locality,
    state: address.region,
    zipCode: address.postal_code,
  };
};

/**
 * Mask a zip code input string to match the format #####-####.
 * @example
 * maskZipCode('12345') = '12345'
 * maskZipCode('123456') = '12345-6'
 * maskZipCode('123456789') = '12345-6789'
 * @param {string} unformattedString The string to mask.
 * @returns {string} The string formatted to match #####-####.
 */
export function maskZipCode(unformattedString: string): string {
  const digits = stripNonNumericChars(unformattedString);

  if (digits.length < 6) return digits;
  return `${digits.slice(0, 5)}-${digits.slice(5, 9)}`;
}
