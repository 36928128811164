import { API, graphqlOperation } from 'aws-amplify';
import { sentenceCase } from 'change-case';

import { ListUsersWithToken, User, UpdateUserAttributesInput, UserNote, CreateUserInput } from 'types/user';
import {
  getUser as getUserQuery,
  listUsers as listUsersQuery,
  getNumberOfUsers as getNumberOfUsersQuery,
} from 'graphql/queries';
import {
  createUser as createUserMutation,
  updateUser as updateUserMutation,
  changeUserStatus as changeUserStatusMutation,
  deleteUser as deleteUserMutation,
} from 'graphql/mutations';
import { group } from 'constants/group';
// utils
import { convertPhoneToAws } from './formatPhone';
import { fromAWSDate, toAWSDate } from './formatTime';
import { convertFromAwsAddressObject, convertToAwsAddressObject } from './formatAddress';
import { status } from 'constants/status';

// ----------------------------------------------------------------------

/**
 * Search an array of user notes for a specific label type.
 * @param {UserNote[]} notes - The array of user notes to search.
 * @param {string} label - The label being searched for.
 * @returns {boolean} Whether the label was present in any user notes.
 */
export const hasLabelType = (notes: UserNote[], label: string): boolean => {
  return Boolean(notes.find((note) => note.type === label));
};

/**
 * Get the label of a user's status for displaying on a page.
 * @param {boolean} isUserApproved - The approval status of the user.
 * @param {boolean} isUserConfirmed - The confirmation status of the user.
 * @param {string | undefined} userDisableReason - The reason that a user's account is disabled. Default is DISABLED.
 * @returns {string} A label for the user's status.
 */
export const getUserStatusLabel = (
  isUserApproved: boolean,
  isUserConfirmed: boolean,
  userDisableReason?: string
): string => {
  if (!isUserConfirmed) return sentenceCase(status.UNCONFIRMED);
  if (isUserApproved) return sentenceCase(status.APPROVED);
  return sentenceCase(userDisableReason ?? 'Disabled');
};

/**
 * Formats the attributes of a newly created user into the format expected by AWS.
 * Optional attributes are excluded if not provided.
 * @param {User} input - The attributes of the user being created.
 * @returns An object containing the formatted user attributes being created.
 */
export const formatCreateUserInput = (input: CreateUserInput) => {
  // These are destructured separately to help TS with the type inferences, since they can be undefined.
  const { expirationDate, netsuiteUrl } = input;
  return {
    address: convertToAwsAddressObject(input.address),
    email: input.email,
    familyName: input.familyName,
    givenName: input.givenName,
    group: input.group.toUpperCase(),
    phoneNumber: convertPhoneToAws(input.phoneNumber),
    ...(expirationDate && { expirationDate: toAWSDate(expirationDate) }),
    ...(netsuiteUrl && { netsuiteUrl }),
  };
};

/**
 * Formats any attributes being updated into the format expected by AWS, as well as
 * excluding any undefined values (i.e. attributes that weren't updated).
 * @param {UpdateUserAttributesInput} input - The attributes of the user being updated.
 * @returns An object containing the formatted user attributes being updated.
 */
export const formatUpdateUserInput = (input: UpdateUserAttributesInput) => {
  const { address, expirationDate, group, phoneNumber, ...rest } = input;
  return {
    ...(address && { address: convertToAwsAddressObject(address) }),
    ...(expirationDate && { expirationDate: toAWSDate(expirationDate) }),
    ...(group && { group: group.toUpperCase() }),
    ...(phoneNumber && { phoneNumber: convertPhoneToAws(phoneNumber) }),
    ...rest,
  };
};

/**
 * Formats the values of a User object into an authenticated user, as
 * well as excluding any optional (undefined) fields.
 * @param {User} user - The user object to format
 * @returns An object of user properties formatted for an auth context authenticated user.
 */
export const formatAuthUserForDisplay = (user: User) => {
  const formattedAddress = (address: string) => {
    return convertFromAwsAddressObject(JSON.parse(address));
  };

  return {
    id: user.username,
    ...(Boolean(user.attributes.address) && { address: formattedAddress(user.attributes.address) }),
    email: user.attributes.email,
    familyName: user.attributes.family_name,
    givenName: user.attributes.given_name,
    phoneNumber: user.attributes.phone_number,
  };
};

/**
 * Gets a user's group membership based on the 'groups' array provided by the
 * user object from useAuth().
 * @param {string[]} groups An array of group names
 * @returns {string} The group the user belongs to.
 */
export const getUserGroup = (groups: string[]): string => {
  if (groups.includes(group.ADMIN)) return `Boundless ${group.ADMIN}`;
  if (groups.includes(group.AGENT)) return `Boundless ${group.AGENT}`;
  return 'Public user';
};

// ----------------------------------------------------------------------

/**
 * Fetches a user from the current Cognito user pool.
 * @param {String} userId - The ID of the user to query.
 * @returns {Promise<User>} The User with the provided ID.
 */
export const getUser = async (userId: string): Promise<User> => {
  interface GetUserResponse {
    data: {
      getUser: User;
    };
  }
  try {
    const res = (await API.graphql(graphqlOperation(getUserQuery, { userId }))) as GetUserResponse;
    return {
      ...res.data.getUser,
      ...(Boolean(res.data.getUser.expirationDate)
        ? { expirationDate: fromAWSDate(res.data.getUser.expirationDate as unknown as string) }
        : {}),
      group: res.data.getUser.group.toLowerCase() as typeof group[keyof typeof group],
    };
  } catch (error) {
    throw new Error(error.errors[0].message);
  }
};

/**
 * Returns a list of the first 60 users present in the current Cognito user pool.
 * @param {String} token - If present, the pagination token to request more users.
 * @returns {Promise<ListUsersWithToken>} A list of the first 60 users in the current Cognito user pool with the pagination token if it exists.
 */
export const listUsers = async (token?: string, filter?: string, groupName?: string): Promise<ListUsersWithToken> => {
  interface ListUsersResponse {
    data: {
      listUsers: ListUsersWithToken;
    };
  }
  try {
    const res = (await API.graphql(
      graphqlOperation(listUsersQuery, { nextToken: token, filter, groupName })
    )) as ListUsersResponse;
    const { users, nextToken } = res.data.listUsers;

    const formatUser = (user: User) => ({
      ...user,
      ...(Boolean(user.expirationDate)
        ? { expirationDate: fromAWSDate(user.expirationDate as unknown as string) }
        : {}),
      group: user.group.toLowerCase() as typeof group[keyof typeof group],
    });

    return {
      users: users.map((user: User) => formatUser(user)),
      ...(Boolean(nextToken) && { nextToken: nextToken }),
    };
  } catch (error) {
    console.error(error);
    throw new Error(error.errors[0].message);
  }
};

/**
 * Creates a user using the appropriate GraphQL mutation.
 * @param {User} userInfo - The information of the user being created.
 * @returns {Promise<string>} The ID of the newly created user.
 */
export const createUser = async (userInfo: CreateUserInput): Promise<string> => {
  interface CreateUserResponse {
    data: {
      createUser: string;
    };
  }
  try {
    const res = (await API.graphql(
      graphqlOperation(createUserMutation, {
        userInfo: formatCreateUserInput(userInfo),
      })
    )) as CreateUserResponse;
    return res.data.createUser;
  } catch (error) {
    // FIXME: The GraphQL operation returns an array of error objects;
    // this currently only displays the first one, if any are present.
    throw new Error(error.errors[0].message);
  }
};

/**
 * Updates a user using the appropriate GraphQL mutation.
 * @param {string} userId - The ID of the user being updated.
 * @param {User} updatedUserInfo - The information of the user being updated.
 * @returns {Promise<string>} The ID of the user being updated.
 */
export const updateUser = async (userId: string, updatedUserInfo: UpdateUserAttributesInput): Promise<string> => {
  interface UpdateUserResponse {
    data: {
      updateUser: string;
    };
  }
  try {
    const res = (await API.graphql(
      graphqlOperation(updateUserMutation, {
        userId: userId,
        updatedUserInfo: formatUpdateUserInput(updatedUserInfo),
      })
    )) as UpdateUserResponse;
    return res.data.updateUser;
  } catch (error) {
    // FIXME: The GraphQL operation returns an array of error objects;
    // this currently only displays the first one, if any are present.
    throw new Error(error.errors[0].message);
  }
};

/**
 * Changes a user status using the appropriate GraphQL mutation.
 * @param {string} userId - The ID of the user being changed.
 * @param {User} isUserApproved - The status of the user.
 * @returns {Promise<boolean>} True if the user was approved / their account was enabled; false otherwise.
 */
export const changeUserStatus = async (userId: string, isUserApproved: boolean): Promise<boolean> => {
  interface ChangeUserStatus {
    data: {
      changeUser: boolean;
    };
  }
  try {
    const res = (await API.graphql(
      graphqlOperation(changeUserStatusMutation, {
        userId: userId,
        isUserApproved: isUserApproved,
      })
    )) as ChangeUserStatus;
    return res.data.changeUser;
  } catch (error) {
    // FIXME: The GraphQL operation returns an array of error objects;
    // this currently only displays the first one, if any are present.
    throw new Error(error.errors[0].message);
  }
};

/**
 * Deletes a user with an unconfirmed account.
 * @param {string} userId - The ID of the user being deleted.
 * @returns {Promise<string>} Resolves with the ID of the user that was deleted.
 */
export const deleteUser = async (userId: string): Promise<string> => {
  interface DeleteUserStatus {
    data: {
      deleteUser: string;
    };
  }
  try {
    const res = (await API.graphql(graphqlOperation(deleteUserMutation, { userId }))) as DeleteUserStatus;
    return res.data.deleteUser;
  } catch (error) {
    throw new Error(error.errors[0].message);
  }
};

/**
 * Returns estimated number of users in current user pool.
 * @returns {Promise<number>} The estimated number of users.
 */
export const getEstimatedNumberOfUsers = async (): Promise<number> => {
  interface GetEstimatedUsersResponse {
    data: {
      getNumberOfUsers: number;
    };
  }
  try {
    const res = (await API.graphql(graphqlOperation(getNumberOfUsersQuery))) as GetEstimatedUsersResponse;
    return res.data.getNumberOfUsers;
  } catch (error) {
    throw new Error(error.errors[0].message);
  }
};
