// routes
import Router from 'routes';
// theme
import ThemeProvider from 'theme';
// components
// import Settings from 'components/settings';
import ScrollToTop from 'components/ScrollToTop';
import NotistackProvider from 'components/NotistackProvider';
import MotionLazyContainer from 'components/animate/MotionLazyContainer';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <NotistackProvider>
        <MotionLazyContainer>
          {/* <Settings /> */}
          <ScrollToTop />
          <Router />
        </MotionLazyContainer>
      </NotistackProvider>
    </ThemeProvider>
  );
}
