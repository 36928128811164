import { createContext, ReactNode, useCallback, useEffect, useReducer } from 'react';
// @types
import { ActionMap, AuthState, AuthUser, AWSCognitoContextType } from 'types/auth';
// aws
import { Auth } from 'aws-amplify';
// utils
import { formatAuthUserForDisplay, getUserGroup } from 'utils/user';
// ----------------------------------------------------------------------

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

enum Types {
  auth = 'AUTHENTICATE',
  logout = 'LOGOUT',
}

type AwsAuthPayload = {
  [Types.auth]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.logout]: undefined;
};

type AwsActions = ActionMap<AwsAuthPayload>[keyof ActionMap<AwsAuthPayload>];

const reducer = (state: AuthState, action: AwsActions) => {
  if (action.type === 'AUTHENTICATE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

const AuthContext = createContext<AWSCognitoContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getUserAttributes = useCallback(async () => {
    const authenticatedUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
    const formattedAuthUserInfo = formatAuthUserForDisplay(authenticatedUser);
    return {
      groups: authenticatedUser.signInUserSession.accessToken.payload['cognito:groups'] ?? [],
      isApproved: true,
      ...formattedAuthUserInfo,
    };
  }, []);

  const getCurrentSession = useCallback(async () => {
    const attributes = await getUserAttributes();
    try {
      await Auth.currentSession().then(() =>
        dispatch({
          type: Types.auth,
          payload: { isAuthenticated: true, user: attributes },
        })
      );
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.auth,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [getUserAttributes]);

  const initial = useCallback(async () => {
    try {
      await getCurrentSession();
    } catch {
      dispatch({
        type: Types.auth,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [getCurrentSession]);

  useEffect(() => {
    initial();
  }, [initial]);

  const login = useCallback(
    async (email, password) => {
      const user = await Auth.signIn(email, password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return {
          status: 'NEW_PASSWORD',
          user,
          setNewPassword: (newPassword: string) => Auth.completeNewPassword(user, newPassword),
        };
      }
      getCurrentSession();
    },
    [getCurrentSession]
  );

  const logout = async () => {
    await Auth.signOut();
    dispatch({ type: Types.logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'cognito',
        user: {
          displayName: `${state?.user?.givenName} ${state?.user?.familyName}`,
          group: getUserGroup(state?.user?.groups ?? []),
          ...state.user,
        },
        getCurrentSession,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
