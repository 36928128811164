/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const changeUserStatus = /* GraphQL */ `
  mutation ChangeUserStatus($userId: String!, $isUserApproved: Boolean!) {
    changeUserStatus(userId: $userId, isUserApproved: $isUserApproved)
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($userInfo: CreateUserInfo!) {
    createUser(userInfo: $userInfo)
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($userId: String!) {
    deleteUser(userId: $userId)
  }
`;
export const joinSupportQueue = /* GraphQL */ `
  mutation JoinSupportQueue($callType: CallType) {
    joinSupportQueue(callType: $callType)
  }
`;
export const leaveSupportQueue = /* GraphQL */ `
  mutation LeaveSupportQueue {
    leaveSupportQueue {
      positionId
      status
    }
  }
`;
export const removeUserFromQueue = /* GraphQL */ `
  mutation RemoveUserFromQueue($userId: String!, $reason: QueueRemovalReason) {
    removeUserFromQueue(userId: $userId, reason: $reason) {
      positionId
      status
    }
  }
`;
export const resendConfirmationEmail = /* GraphQL */ `
  mutation ResendConfirmationEmail($userId: String!) {
    resendConfirmationEmail(userId: $userId)
  }
`;
export const requestAccountDeletion = /* GraphQL */ `
  mutation RequestAccountDeletion {
    requestAccountDeletion
  }
`;
export const sendCallStartedNotification = /* GraphQL */ `
  mutation SendCallStartedNotification($clientToNotify: ID!) {
    sendCallStartedNotification(clientToNotify: $clientToNotify)
  }
`;
export const setUserPassword = /* GraphQL */ `
  mutation SetUserPassword(
    $userId: String!
    $password: String!
    $isTemporary: Boolean!
  ) {
    setUserPassword(
      userId: $userId
      password: $password
      isTemporary: $isTemporary
    )
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($userId: String!, $updatedUserInfo: UpdateUserInfo!) {
    updateUser(userId: $userId, updatedUserInfo: $updatedUserInfo)
  }
`;
export const createUserNote = /* GraphQL */ `
  mutation CreateUserNote(
    $input: CreateUserNoteInput!
    $condition: ModelUserNoteConditionInput
  ) {
    createUserNote(input: $input, condition: $condition) {
      clientId
      agentEmail
      agentId
      createdAt
      content
      name
      type
      updatedAt
    }
  }
`;
export const updateUserNote = /* GraphQL */ `
  mutation UpdateUserNote(
    $input: UpdateUserNoteInput!
    $condition: ModelUserNoteConditionInput
  ) {
    updateUserNote(input: $input, condition: $condition) {
      clientId
      agentEmail
      agentId
      createdAt
      content
      name
      type
      updatedAt
    }
  }
`;
export const deleteUserNote = /* GraphQL */ `
  mutation DeleteUserNote(
    $input: DeleteUserNoteInput!
    $condition: ModelUserNoteConditionInput
  ) {
    deleteUserNote(input: $input, condition: $condition) {
      clientId
      agentEmail
      agentId
      createdAt
      content
      name
      type
      updatedAt
    }
  }
`;
export const updateSupportAvailabilityWeekly = /* GraphQL */ `
  mutation UpdateSupportAvailabilityWeekly(
    $input: UpdateSupportAvailabilityWeeklyInput!
    $condition: ModelSupportAvailabilityWeeklyConditionInput
  ) {
    updateSupportAvailabilityWeekly(input: $input, condition: $condition) {
      id
      day0 {
        closeAt
        isEnabled
        openAt
      }
      day1 {
        closeAt
        isEnabled
        openAt
      }
      day2 {
        closeAt
        isEnabled
        openAt
      }
      day3 {
        closeAt
        isEnabled
        openAt
      }
      day4 {
        closeAt
        isEnabled
        openAt
      }
      day5 {
        closeAt
        isEnabled
        openAt
      }
      day6 {
        closeAt
        isEnabled
        openAt
      }
      isEnabled
      createdAt
      updatedAt
    }
  }
`;
