import { stripNonNumericChars } from './formatText';

/**
 * Removes the country code from phone numbers received from AWS.
 * @param {string} input The string to format.
 * @returns {string} The string with the US country code removed.
 */
export function convertPhoneFromAws(input: string): string {
  return input.replace(/^\+1/, '');
}

/**
 * Formats a phone number for AWS by removing any non-numeric characters
 * and adding the US country code ('+1').
 * @param {string} input The string to format.
 * @returns {string} The formatted string matching AWS phone number format.
 */
export function convertPhoneToAws(input: string): string {
  return `+1${stripNonNumericChars(input)}`;
}

/**
 * Mask a phone number input string to match the format (###) ###-####.
 * @example
 * maskPhoneInput('1234') = '1234'
 * maskPhoneInput('12345') = '(123) 45'
 * maskPhoneInput('1234567890') = '(123) 456-7890'
 * @param {string} unformattedString The string to mask.
 * @returns {string} The string formatted to match (###) ###-####.
 */
export function maskPhoneNumber(unformattedString: string): string {
  const digits = stripNonNumericChars(convertPhoneFromAws(unformattedString));

  if (digits.length < 4) return digits;
  if (digits.length < 7) return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
  return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
}
