/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCallSessionToken = /* GraphQL */ `
  query GetCallSessionToken {
    getCallSessionToken {
      sessionId
      sessionToken
    }
  }
`;
export const getCallSessionTokenAsAgent = /* GraphQL */ `
  query GetCallSessionTokenAsAgent($clientUserId: ID!) {
    getCallSessionTokenAsAgent(clientUserId: $clientUserId) {
      sessionId
      sessionToken
    }
  }
`;
export const getNumberOfUsers = /* GraphQL */ `
  query GetNumberOfUsers {
    getNumberOfUsers
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($userId: String!) {
    getUser(userId: $userId) {
      id
      expirationDate
      address {
        street
        city
        state
        zipCode
      }
      isApproved
      isConfirmed
      isOAuth
      identities {
        dateCreated
        issuer
        primary
        providerName
        providerType
        userId
      }
      reasonForDisable
      email
      givenName
      familyName
      netsuiteUrl
      phoneNumber
      group
      tags
    }
  }
`;
export const getSupportQueue = /* GraphQL */ `
  query GetSupportQueue {
    getSupportQueue {
      positionId
      status
    }
  }
`;
export const getSupportQueueAsAgent = /* GraphQL */ `
  query GetSupportQueueAsAgent {
    getSupportQueueAsAgent {
      username
      agentUsername
      positionId
      zoomSessionId
      givenName
      familyName
      callType
      phoneNumber
      tags
      status
      joinedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers($nextToken: String, $filter: String, $groupName: String) {
    listUsers(nextToken: $nextToken, filter: $filter, groupName: $groupName) {
      users {
        id
        expirationDate
        address {
          street
          city
          state
          zipCode
        }
        isApproved
        isConfirmed
        isOAuth
        identities {
          dateCreated
          issuer
          primary
          providerName
          providerType
          userId
        }
        reasonForDisable
        email
        givenName
        familyName
        netsuiteUrl
        phoneNumber
        group
        tags
      }
      nextToken
    }
  }
`;
export const isSupportOpen = /* GraphQL */ `
  query IsSupportOpen {
    isSupportOpen
  }
`;
export const getUserNote = /* GraphQL */ `
  query GetUserNote($clientId: ID!, $createdAt: AWSDateTime!) {
    getUserNote(clientId: $clientId, createdAt: $createdAt) {
      clientId
      agentEmail
      agentId
      createdAt
      content
      name
      type
      updatedAt
    }
  }
`;
export const listUserNotes = /* GraphQL */ `
  query ListUserNotes(
    $clientId: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelUserNoteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserNotes(
      clientId: $clientId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        clientId
        agentEmail
        agentId
        createdAt
        content
        name
        type
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSupportAvailabilityWeekly = /* GraphQL */ `
  query GetSupportAvailabilityWeekly($id: ID!) {
    getSupportAvailabilityWeekly(id: $id) {
      id
      day0 {
        closeAt
        isEnabled
        openAt
      }
      day1 {
        closeAt
        isEnabled
        openAt
      }
      day2 {
        closeAt
        isEnabled
        openAt
      }
      day3 {
        closeAt
        isEnabled
        openAt
      }
      day4 {
        closeAt
        isEnabled
        openAt
      }
      day5 {
        closeAt
        isEnabled
        openAt
      }
      day6 {
        closeAt
        isEnabled
        openAt
      }
      isEnabled
      createdAt
      updatedAt
    }
  }
`;
export const listSupportAvailabilityWeeklies = /* GraphQL */ `
  query ListSupportAvailabilityWeeklies(
    $filter: ModelSupportAvailabilityWeeklyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSupportAvailabilityWeeklies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        day0 {
          closeAt
          isEnabled
          openAt
        }
        day1 {
          closeAt
          isEnabled
          openAt
        }
        day2 {
          closeAt
          isEnabled
          openAt
        }
        day3 {
          closeAt
          isEnabled
          openAt
        }
        day4 {
          closeAt
          isEnabled
          openAt
        }
        day5 {
          closeAt
          isEnabled
          openAt
        }
        day6 {
          closeAt
          isEnabled
          openAt
        }
        isEnabled
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
