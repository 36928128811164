/**
 * Extract an error message from an unknown type. This is useful for libraries where any type of value may be thrown
 * such as a string or number rather than an instance of Error.
 * @param {unknown} error The object to attempt to extract an error message from.
 * @returns {string} The message of the error.
 */
export const extractErrorMessage = (error: unknown): string => {
  if (error instanceof Error) {
    return error.message;
  }
  return String(error);
};

/**
 * Ensure a valid error is returned from whatever object is passed. This is useful when any type of value may be thrown
 * from a function, not only an instance of Error.
 * @param {unknown} error The object specifying an error.
 * @returns {Error} The original error or a new formatted error.
 */
export const formatUnknownError = (error: unknown): Error => {
  if (error instanceof Error) {
    return error;
  }
  return new Error(extractErrorMessage(error));
};
