/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onLeaveSupportQueue = /* GraphQL */ `
  subscription OnLeaveSupportQueue {
    onLeaveSupportQueue {
      positionId
      status
    }
  }
`;
export const onJoinSupportQueue = /* GraphQL */ `
  subscription OnJoinSupportQueue {
    onJoinSupportQueue
  }
`;
