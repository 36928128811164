import { API, graphqlOperation } from 'aws-amplify';

import { sendCallStartedNotification as sendNotification } from 'graphql/mutations';
import { getCallSessionTokenAsAgent } from 'graphql/queries';
import { ZoomSession } from 'API';

/**
 * Send a push notification to a client specifying that a Zoom call has started.
 * @param {string} clientId The ID of the public user being called.
 * @returns {Promise<void>} A promise that resolves if the push notification is delivered to Expo.
 */
export const sendCallStartedNotification = async (clientId: string): Promise<void> => {
  try {
    await API.graphql(graphqlOperation(sendNotification, { clientToNotify: clientId }));
  } catch (error) {
    throw new Error(error.errors[0].message);
  }
};

/**
 * Get a Zoom session token for joining a call as the host with the specified client.
 * @param {string} clientId The ID of the public user being called.
 * @returns {Promise<ZoomSession>} An object containing the session ID and the host's JWT for the newly created session.
 */
export const getZoomSessionToken = async (clientId: string): Promise<ZoomSession> => {
  interface ZoomSessionResponse {
    data: {
      getCallSessionTokenAsAgent: ZoomSession;
    };
  }

  try {
    const res = (await API.graphql(
      graphqlOperation(getCallSessionTokenAsAgent, { clientUserId: clientId })
    )) as ZoomSessionResponse;
    return res.data.getCallSessionTokenAsAgent;
  } catch (error) {
    throw new Error(error.errors[0].message);
  }
};
