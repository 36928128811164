import * as React from 'react';
import { Container, Box } from '@mui/material';
import Image from 'components/Image';
import Logo from 'assets/boundless_logo_1x.png';
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
  logoBottomPadding: number;
};

const AuthContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function AuthLogoLayout({ children, logoBottomPadding }: Props) {
  return (
    <Container maxWidth="sm">
      <AuthContentStyle>
        <Box alignItems="center" sx={{ pb: logoBottomPadding }}>
          <Image disabledEffect src={Logo} />
        </Box>
        {children}
      </AuthContentStyle>
    </Container>
  );
}
