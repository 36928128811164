import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 400 400"
        /* style={{
          enableBackground: 'new 0 0 400 400',
        }} */
        xmlSpace="preserve"
      >
        <style>{'.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#626366}'}</style>
        <path
          className="st0"
          d="M80.6 96.6c5.4-7 146.9-56.3 207-32.2 60.6 23.6-34.9 128.2-52 137.3-17.7 9.7 17.2-30.6 16.6-79.4 0-48.3-76.7-20.9-101.9-16.1-25.1 5.4-69.7-9.6-69.7-9.6"
        />
        <path
          className="st0"
          d="M229.2 202.2c3.8-4.3 105.1-39.1 148.5-22 42.9 16.6-25.2 91.2-38.1 98.1-12.3 7 12.3-22.5 12.3-57.4 0-34.3-55.2-15-72.9-11.3-18.2 3.9-49.8-7.4-49.8-7.4"
        />
        <path
          d="M54.9 179.1c-4.3 11.3-6.4 23.1-6.4 34.9 0 72.9 78.8 121.2 175.4 108.9 70.8-9.1 131.4-48.3 159.3-97.1-29 57.4-98.7 103.5-180.2 114.2C95.7 354 8.3 299.8 8.3 219.4c0-17.7 4.3-34.3 11.8-50.9 9.6-22.1 38.6-15.6 34.8 10.6"
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: '#54b848',
          }}
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
