import * as React from 'react';
import { IconButton, InputAdornment, TextFieldProps } from '@mui/material';
import { RHFTextField } from './hook-form';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

type Props = {
  name: string;
  showPassword: boolean;
  setShowPassword: Function;
};

export function PasswordInput({ name, showPassword, setShowPassword, ...other }: Props & TextFieldProps) {
  return (
    <RHFTextField
      name={name}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
              <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...other}
    />
  );
}
